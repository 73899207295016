/* scrollbar.css */
::-webkit-scrollbar {
    width: 5px;
    max-height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    /* background-color: #f1f1f1; */
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
  }